/// A directive for uploading files to the server.
/// This directives onFileChange method needs to be called by the directive that monitors changes to the select file input (fgOnFileChange)

import angular, { auto } from 'angular';
import { Services } from '../../@types/services';
import { SharedAngular } from '../../@types/sharedAngular';

/// ##########################################################################################################
///
/// NOTE: THIS DIRECTIVE DOES NOT HAVE A TEMPLATE. fileupload.ng.html is NOT THE TEMPLATE FOR THIS DIRECTIVE!!!
///
/// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

angular.module('fgFiles').directive('fgFileUpload', [fgFileUpload]);

function fgFileUpload() {
  const directive = {
    restrict: 'A',
    require: ['?^fgForm', 'fgFileUpload'],
    controllerAs: 'ctrl',
    controller: [
      'fgFileListService',
      'pubsubService',
      'notificationService',
      'fileService',
      'sessionService',
      '$injector',
      'guidService',
      function controller(
        fgFileListService: Services.FgFileListService,
        pubsubService: SharedAngular.PubSubService,
        notificationService: SharedAngular.NotificationService,
        fileService: SharedAngular.FileService,
        sessionService: SharedAngular.SessionService,
        $injector: auto.IInjectorService,
        guidService: SharedAngular.GuidService
      ) {
        const BATCH_SIZE = 5;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const ctrl = this;

        if ($injector.has('runnerPublicFormService')) {
          ctrl.runnerPublicFormService = $injector.get(
            'runnerPublicFormService'
          );
        }
        ctrl.fileError = false;
        ctrl.fieldName = '';
        ctrl.init = init;
        ctrl.onFileChange = onFileChange;
        ctrl.uploadFiles = uploadFiles;
        ctrl.user = sessionService.getUser();

        function init(fieldName: string, fgFormCtrl) {
          ctrl.stepId = fgFormCtrl.stepId;
          ctrl.fieldName = fieldName;
          ctrl.formModelId = fgFormCtrl.formModelId;
          ctrl.isPublicForm = fgFormCtrl.isPublic;
        }

        function onFileChange(event) {
          if (event.target.files.length === 0 || ctrl.stepId == '') {
            // No files or not on a real step (i.e. we are in modeler)
            return;
          }
          pubsubService.publish('FILEUPLOAD_UPLOAD_STARTED');
          //handle file change notification (from fgOnFileChange)
          event.stopPropagation();
          const filesToUpload = event.target.files;
          ctrl.uploadFiles(filesToUpload);
        }

        function uploadFiles(filesToUpload) {
          uploadFileBatch(
            Array.prototype.slice.call(filesToUpload),
            0,
            BATCH_SIZE
          );
        }

        function uploadFileBatch(files, start: number, batchSize: number) {
          if (!files || batchSize <= 0 || start >= files.length) {
            return;
          }

          const batch = files.slice(start, start + batchSize);
          const promises = batch.map((file: File) => {
            if (ctrl.stepId == undefined || ctrl.stepId == '') {
              //stepId will be undefined if we are uploading a file for a flow not yet started
              ctrl.stepId = guidService.empty();
            }

            let uploadPromise;
            if (ctrl.user === undefined) {
              uploadPromise = ctrl.runnerPublicFormService.uploadAnonymousFile(
                ctrl.formModelId,
                ctrl.stepId,
                file,
                ctrl.fieldName
              );
            } else {
              uploadPromise = fileService.uploadFile(
                ctrl.stepId,
                file,
                ctrl.fieldName
              );
            }
            return uploadPromise.then(
              (response) => {
                const fileId = response.data;
                //add file meta to the list (this will update the list directive)
                fgFileListService.addFile(
                  {
                    //stepId is used to keep track of files associated to this step
                    stepId: ctrl.stepId,
                    filename: file.name,
                    id: response.data,
                    key: ctrl.fieldName,
                    downloadLink: fileService.getDownloadLink(
                      fileId,
                      ctrl.isPublicForm
                    ),
                    size: Math.floor(file.size / 1000)
                  },
                  ctrl.fieldName,
                  ctrl.stepId
                );

                pubsubService.publish('FILEUPLOAD_UPLOAD_COMPLETED');
                notificationService.showSuccessToast(file.name + ' uploaded');
              },
              function (error) {
                //file upload failed
                //clear the file control so that user can upload another file

                pubsubService.publish('FILEUPLOAD_UPLOAD_FAILED');
              }
            );
          });

          Promise.all(promises).then(() =>
            uploadFileBatch(files, start + batchSize, batchSize)
          );
        }
      }
    ],

    link: function (scope, element, attrs, ctrls) {
      const fgFormCtrl = ctrls[0];
      const fgFileUploadCtrl = ctrls[1];
      //we need to pass through the name of the field on the form
      if (fgFormCtrl != null) {
        fgFileUploadCtrl.init(attrs.fieldName, fgFormCtrl);
      }
    }
  };

  return directive;
}

import angular from 'angular';
const fg = angular.module('fg');

// add all .html to templateCache
fg.run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'angular-form-gen/form/form-fields/form-fields.ng.html',
      require('./form/form-fields/form-fields.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/edit/canvas/field/fg-edit-canvas-field.ng.html',
      require('./edit/canvas/field/fg-edit-canvas-field.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/form/field/field.ng.html',
      require('./form/field/field.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/validation/summary.ng.html',
      require('./validation/summary.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/signature.ng.html',
      require('./field-templates/default/signature.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/number.ng.html',
      require('./field-templates/default/number.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/image.ng.html',
      require('./field-templates/default/image.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/not-in-cache.ng.html',
      require('./field-templates/default/not-in-cache.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/currency.ng.html',
      require('./field-templates/default/currency.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/text.ng.html',
      require('./field-templates/default/text.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/textarea.ng.html',
      require('./field-templates/default/textarea.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/email.ng.html',
      require('./field-templates/default/email.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/password.ng.html',
      require('./field-templates/default/password.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/checkbox.ng.html',
      require('./field-templates/default/checkbox.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/tasklist.ng.html',
      require('./field-templates/default/tasklist.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/multiselectlist.ng.html',
      require('./field-templates/default/multiselectlist.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/selectlist.ng.html',
      require('./field-templates/default/selectlist.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/radiobuttonlist.ng.html',
      require('./field-templates/default/radiobuttonlist.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/recaptcha.ng.html',
      require('./field-templates/default/recaptcha.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/approvalrule.ng.html',
      require('./field-templates/default/approvalrule.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/approvalcomment.ng.html',
      require('./field-templates/default/approvalcomment.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/submit.ng.html',
      require('./field-templates/default/submit.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/instruction.ng.html',
      require('./field-templates/default/instruction.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/date.ng.html',
      require('./field-templates/default/date.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/datetime.ng.html',
      require('./field-templates/default/datetime.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/lookup.ng.html',
      require('./field-templates/default/lookup.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/formula.ng.html',
      require('./field-templates/default/formula.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/form/files/image.ng.html',
      require('./form/files/image.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/common/jsonify/jsonify.ng.html',
      require('./common/jsonify/jsonify.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/common/tabs/tabs.ng.html',
      require('./common/tabs/tabs.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/common/tabs/tabs-pane.ng.html',
      require('./common/tabs/tabs-pane.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/edit/edit.ng.html',
      require('./edit/edit.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/edit/canvas/canvas.ng.html',
      require('./edit/canvas/canvas.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/edit/canvas/dropzone/dropzone.ng.html',
      require('./edit/canvas/dropzone/dropzone.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/edit/canvas/field/properties/properties.ng.html',
      require('./edit/canvas/field/properties/properties.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/edit/canvas/field/properties/options/options.ng.html',
      require('./edit/canvas/field/properties/options/options.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/edit/canvas/field/properties/property-field/common.ng.html',
      require('./edit/canvas/field/properties/property-field/common.ng.html')
        .default
    );

    $templateCache.put(
      'angular-form-gen/edit/canvas/field/properties/property-field/field-value.ng.html',
      require('./edit/canvas/field/properties/property-field/field-value.ng.html')
        .default
    );

    $templateCache.put(
      'angular-form-gen/edit/canvas/field/properties/property-field/property-field.ng.html',
      require('./edit/canvas/field/properties/property-field/property-field.ng.html')
        .default
    );

    $templateCache.put(
      'angular-form-gen/edit/canvas/field/properties/validation/validation.ng.html',
      require('./edit/canvas/field/properties/validation/validation.ng.html')
        .default
    );

    $templateCache.put(
      'angular-form-gen/edit/canvas/field/properties/validation/validation-message.ng.html',
      require('./edit/canvas/field/properties/validation/validation-message.ng.html')
        .default
    );

    $templateCache.put(
      'angular-form-gen/edit/palette/palette.ng.html',
      require('./edit/palette/palette.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/edit/palette/categories/categories.ng.html',
      require('./edit/palette/categories/categories.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/attachdocument.ng.html',
      require('./field-templates/default/attachdocument.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/dropdownlist.ng.html',
      require('./field-templates/default/dropdownlist.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/fileupload.ng.html',
      require('./field-templates/default/fileupload.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/form/field/fieldPalette.ng.html',
      require('./form/field/fieldPalette.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/default/table.ng.html',
      require('./field-templates/default/table.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/form/files/image.selector.ng.html',
      require('./form/files/image.selector.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/form/files/file.confirmDelete.ng.html',
      require('./form/files/file.confirmDelete.ng.html').default
    );

    $templateCache.put(
      'angular-form-gen/field-templates/properties/approvalcomment.html',
      require('./field-templates/properties/approvalcomment.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/approvalrule.ng.html',
      require('./field-templates/properties/approvalrule.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/attachdocument.ng.html',
      require('./field-templates/properties/attachdocument.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/checkbox.ng.html',
      require('./field-templates/properties/checkbox.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/currency.ng.html',
      require('./field-templates/properties/currency.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/date.ng.html',
      require('./field-templates/properties/date.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/datetime.ng.html',
      require('./field-templates/properties/datetime.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/dropdownlist.ng.html',
      require('./field-templates/properties/dropdownlist.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/email.ng.html',
      require('./field-templates/properties/email.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/fileupload.ng.html',
      require('./field-templates/properties/fileupload.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/formula.ng.html',
      require('./field-templates/properties/formula.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/image.ng.html',
      require('./field-templates/properties/image.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/instruction.ng.html',
      require('./field-templates/properties/instruction.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/lookup.ng.html',
      require('./field-templates/properties/lookup.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/multiselectlist.ng.html',
      require('./field-templates/properties/multiselectlist.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/number.ng.html',
      require('./field-templates/properties/number.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/password.ng.html',
      require('./field-templates/properties/password.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/radiobuttonlist.ng.html',
      require('./field-templates/properties/radiobuttonlist.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/selectlist.ng.html',
      require('./field-templates/properties/selectlist.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/signature.ng.html',
      require('./field-templates/properties/signature.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/submit.ng.html',
      require('./field-templates/properties/submit.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/table.ng.html',
      require('./field-templates/properties/table.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/tasklist.ng.html',
      require('./field-templates/properties/tasklist.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/recaptcha.ng.html',
      require('./field-templates/properties/recaptcha.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/text.ng.html',
      require('./field-templates/properties/text.ng.html').default
    );
    $templateCache.put(
      'angular-form-gen/field-templates/properties/textarea.ng.html',
      require('./field-templates/properties/textarea.ng.html').default
    );
  }
]);

//create a namespaced store
fg.factory('flowStore', [
  'store',
  function (store) {
    return store.getNamespacedStore('flow');
  }
]);

/**
 * Constructor for form-gen Field types.
 * @param {string} type         Indicates the type of field
 * @param {object} properties   [optional] Initial property values
 */
fg.constant('FgField', function FgField(type, properties) {
  this.name = this.type = type;

  if (properties) {
    angular.extend(this, properties);
  }

  this.displayName =
    this.displayName ||
    this.type.charAt(0).toUpperCase() + this.type.substring(1);
});

function fgConfigProvider() {
  const config = {
    //If TRUE displays a dummy done button on forms
    //https://bizflo.atlassian.net/wiki/display/TECH/Angular+Form+Gen
    showDummyDoneButton: true,
    enableDebugInfo: false,
    validation: {
      messages: {},
      patterns: {}
    },
    fields: {
      templates: [],
      categories: {},
      renderInfo: {}
    }
  };

  const templates = config.fields.templates;

  function indexOfTemplate(type) {
    let idx = templates.length;

    while (idx--) {
      if (templates[idx].type === type) {
        break;
      }
    }

    return idx;
  }

  return {
    debug: function (value) {
      config.enableDebugInfo = value;
    },
    setShowDummyDoneButton: function (value) {
      config.showDummyDoneButton = value;
    },
    fields: {
      add: function (
        objectTemplate,
        categories,
        templateUrl,
        propertiesTemplateUrl
      ) {
        if (
          !objectTemplate ||
          !objectTemplate.type ||
          !categories ||
          !categories.length
        ) {
          throw new Error(
            'Need a valid objectTemplate and at least one category'
          );
        }

        const idx = indexOfTemplate(objectTemplate.type);

        if (idx !== -1) {
          templates[idx] = objectTemplate;
        } else {
          templates.push(objectTemplate);
        }

        this.category(objectTemplate.type, categories);
        this.renderInfo(
          objectTemplate.type,
          templateUrl,
          propertiesTemplateUrl
        );
      },
      remove: function (type) {
        const idx = indexOfTemplate(type);

        if (idx !== -1) {
          templates.splice(idx, 1);
        }

        this.category(type);
        this.renderInfo(type);
      },
      renderInfo: function (fieldType, templateUrl, propertiesTemplateUrl) {
        config.fields.renderInfo[fieldType] = {
          templateUrl: templateUrl,
          propertiesTemplateUrl: propertiesTemplateUrl
        };
      },
      category: function (fieldType, categories) {
        if (!angular.isArray(categories)) {
          categories = [categories];
        }

        /*                    angular.forEach(config.fields.categories, function (category) {
                        delete category[fieldType];
                    });*/

        angular.forEach(categories, function (category) {
          if (config.fields.categories[category] === undefined) {
            config.fields.categories[category] = {};
          }

          config.fields.categories[category][fieldType] = true;
        });
      }
    },
    validation: {
      message: function (typeOrObject, message) {
        const messages = config.validation.messages;

        if (angular.isString(typeOrObject)) {
          if (!message) {
            throw new Error('No message specified for ' + typeOrObject);
          }

          messages[typeOrObject] = message;
        } else {
          angular.extend(messages, typeOrObject);
        }
      },
      pattern: function (nameOrObject, pattern) {
        if (angular.isString(nameOrObject)) {
          config.validation.patterns[name] = pattern;
        } else {
          angular.extend(config.validation.patterns, nameOrObject);
        }
      }
    },
    $get: function () {
      return config;
    }
  };
}
fg.provider('fgConfig', fgConfigProvider);

fg.config([
  'fgConfigProvider',
  'FgField',
  function (fgConfigProvider, FgField) {
    // - - - - - - - - - - - - - - - - - - - - - -
    // Messages
    // - - - - - - - - - - - - - - - - - - - - - -

    fgConfigProvider.validation.message({
      required:
        'A {{ field.validationTypeName }} value is required for this field.',
      minlength:
        'The value does not match the minimum length{{ field.schema && (" of " + field.schema.validation.minlength + " characters" || "")}}.',
      /*
       * Originally this is referencing field.schema.validation and not customValidation. Validation is unused
       * now and customValidation is the one that is being heavily used, so we 're going to follow suite
       * and usecustomValidation instead of validation.
       *                                                                           -Cassey
       */
      maxlength:
        'The value exceeds the maximum length{{ field.schema && (" of " + field.schema.customValidation.maxLength + " characters" || "")}}.',
      pattern:
        'The value "{{ field.state.$viewValue }}" does not match the required format.',
      unique: 'The value "{{ field.state.$viewValue }}" is already in use.',
      number: 'The value is not a number.',
      min: 'The value {{ field.schema && ("should be at least " + field.schema.validation.min) || field.state.$viewValue + " is too low" }}',
      max: 'The value {{ field.schema && ("should be less than " + field.schema.validation.max) || field.state.$viewValue + " is too high" }}'
    });

    // - - - - - - - - - - - - - - - - - - - - - -
    // Fields
    // - - - - - - - - - - - - - - - - - - - - - -

    const categories = {
      'Text input fields': [
        new FgField('text', {
          displayName: 'Short text',
          typeName: 'Short text',
          validation: {
            required: true
          }
        }),
        new FgField('textarea', {
          displayName: 'Long text',
          typeName: 'Long text',
          validation: {
            required: true
          }
        }),
        new FgField('fileupload', {
          displayName: 'File upload',
          typeName: 'File upload',
          nolabel: true,
          validation: {
            required: true
          }
        })
      ],
      'Table fields': [
        new FgField('table', {
          displayName: 'Table',
          typeName: 'Table',
          validation: {
            required: true
          }
        })
      ],
      'Select input fields': [
        new FgField('selectlist', {
          displayName: 'Dropdown list',
          typeName: 'Dropdown list',
          validation: {
            required: true
          },
          options: [
            {
              value: '1',
              text: 'Option 1'
            },
            {
              value: '2',
              text: 'Option 2'
            },
            {
              value: '3',
              text: 'Option 3'
            }
          ],
          //default value needs to be emmpty so that our placeholders are used
          value: ''
        }),
        new FgField('radiobuttonlist', {
          displayName: 'Option list',
          typeName: 'Option list',
          validation: {
            required: true
          },
          options: [
            {
              value: '1',
              text: 'Option 1'
            },
            {
              value: '2',
              text: 'Option 2'
            },
            {
              value: '3',
              text: 'Option 3'
            }
          ]
        }),
        new FgField('approvalrule', {
          displayName: 'Approval rule',
          typeName: 'Approval rule',
          hideInPalette: true,
          actionButtons: {
            hideSettings: true,
            hideDelete: true
          },
          validation: {
            required: true
          },
          options: [
            {
              value: '1',
              text: 'Approve'
            },
            {
              value: '0',
              text: 'Reject'
            }
          ]
        }),
        new FgField('dynamicactors', {
          displayName: 'Select who will be assigned to the next step',
          typeName: 'Dynamic actors',
          hideInPalette: true,
          actionButtons: {
            hideSettings: false,
            hideDelete: true
          },
          validation: {
            required: true
          },
          options: []
        })
      ],
      'Checkbox fields': [
        new FgField('checkbox', {
          displayName: 'Check box',
          nolabel: true,
          typeName: 'Check box',
          validation: {
            required: true
          }
        }),
        new FgField('tasklist', {
          typeName: 'Task list',
          displayName: 'Task list',
          validation: {
            required: true,
            messages: {
              required:
                'You must complete all task list items to complete this form'
            }
          },
          options: [
            {
              value: '1',
              checked: true,
              text: 'Task 1'
            },
            {
              value: '2',
              text: 'Task 2'
            }
          ]
        }),
        new FgField('multiselectlist', {
          typeName: 'Multi-selection list',
          displayName: 'Multi-selection list',
          validation: {
            required: true,
            messages: {
              required: 'You must select at least 1 item from the list'
            }
          },
          options: [
            {
              value: '1',
              checked: true,
              text: 'Option 1'
            },
            {
              value: '2',
              text: 'Option 2'
            }
          ]
        }),
        new FgField('recaptcha', {
          displayName: 'ReCaptcha',
          nolabel: true,
          typeName: 'ReCaptcha',
          disabled: false,
          disableUniqueName: true,
          shrunk: true,
          actionButtons: {
            hideSettings: true,
            disableMovebuttons: true
          },
          validation: {
            required: true,
            messages: {
              required: 'Complete the reCAPTCHA'
            }
          }
        })
      ],
      'Other input fields': [
        new FgField('signature', {
          displayName: 'Signature',
          typeName: 'signature',
          nolabel: true,
          validation: {
            required: true
          }
        }),

        new FgField('currency', {
          displayName: 'Currency',
          typeName: 'Currency',
          validation: {
            maxlength: 15 /* to prevent > Number.MAX_VALUE */,
            required: true
          }
        }),
        new FgField('email', {
          displayName: 'Email',
          typeName: 'Email',
          validation: {
            required: true
          }
        }),
        new FgField('number', {
          displayName: 'Number',
          typeName: 'Number',
          validation: {
            maxlength: 15 /* to prevent > Number.MAX_VALUE */,
            required: true
          }
        }),
        new FgField('date', {
          displayName: 'Date',
          typeName: 'Date',
          validation: {
            required: true
          }
        }),
        new FgField('datetime', {
          typeName: 'Date Time',
          displayName: 'Date Time',
          validation: {
            required: true,
            messages: {
              required: 'A date time value is required for this field'
            }
          }
        }),
        new FgField('formula', {
          displayName: 'Formula',
          typeName: 'Formula',
          validation: {}
        })
      ],
      'Read-only fields': [
        new FgField('instruction', {
          displayName: 'Instruction',
          typeName: 'Instruction',
          nolabel: true,
          value: 'Enter the instructions to be displayed here.'
        }),
        new FgField('attachdocument', {
          displayName: 'Attach Documents',
          typeName: 'Attach Documents',
          nolabel: true,
          validation: {
            required: false
          }
        })
      ],
      'Database fields': [
        new FgField('text', {
          displayName: 'Short text',
          typeName: 'Short text',
          validation: {
            required: true
          }
        }),
        new FgField('email', {
          displayName: 'Email',
          typeName: 'Email',
          validation: {
            required: true
          }
        }),
        new FgField('currency', {
          displayName: 'Currency',
          typeName: 'Currency',
          validation: {
            maxlength: 15 /* to prevent > Number.MAX_VALUE */,
            required: true
          }
        }),
        new FgField('number', {
          displayName: 'Number',
          typeName: 'Number',
          validation: {
            maxlength: 15 /* to prevent > Number.MAX_VALUE */,
            required: true
          }
        }),
        new FgField('selectlist', {
          displayName: 'Dropdown list',
          typeName: 'Dropdown list',
          validation: {
            required: true
          },
          options: [
            {
              value: '1',
              text: 'Option 1'
            },
            {
              value: '2',
              text: 'Option 2'
            },
            {
              value: '3',
              text: 'Option 3'
            }
          ],
          //default value needs to be emmpty so that our placeholders are used
          value: ''
        }),
        new FgField('lookup', {
          displayName: 'Lookup Field',
          typeName: 'Lookup',
          validation: {}
        }),
        new FgField('table', {
          displayName: 'Table',
          typeName: 'Table',
          validation: {
            required: true
          }
        })
      ]
    };

    //This effectively sorts the fields we see available in the modeler task designer!
    //Search keys (so this can be found): fieldType, field.type, templateType, templates, template
    angular.forEach(categories, function (fields, category) {
      angular.forEach(fields, function (field) {
        fgConfigProvider.fields.add(
          field,
          category /*, templateUrl, propertiesTemplateUrl */
        );
      });
    });

    // - - - - - - - - - - - - - - - - - - - - - -
    // Patterns
    // - - - - - - - - - - - - - - - - - - - - - -

    fgConfigProvider.validation.pattern({
      None: undefined,
      Url: '^(https?:\\/\\/)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\.-]*)*\\/?$',
      Domain: '^([a-z][a-z0-9\\-]+(\\.|\\-*\\.))+[a-z]{2,6}$',
      'IPv4 Address':
        '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
      'Email Address':
        "/^[a-zA-Z0-9\\-_]+([\\.'+][a-zA-Z0-9\\-_]+)*@[a-z0-9]+(\\-[a-z0-9]+)*(\\.[a-z0-9]+(\\-[a-z0-9]+)*)*\\.[a-z]{2,4}$/i",
      Integer: '^-{0,1}\\d+$',
      'Positive Integers': '^\\d+$',
      'Negative Integers': '^-\\d+$',
      Number: '^-{0,1}\\d*\\.{0,1}\\d+$',
      'Positive Number': '^\\d*\\.{0,1}\\d+$',
      'Negative Number': '^-\\d*\\.{0,1}\\d+$',
      'Year (1920-2099)': '^(19|20)[\\d]{2,2}$',
      Password:
        '(?=.*\\d)(?=.*[!@#$%^&*\\-=()|?."\';:]+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$'
    });
  }
]);

export type FgConfigProviderType = ReturnType<typeof fgConfigProvider>;

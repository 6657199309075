//
// A service that maintains a list of uploaded files
//
import angular, { IQService } from 'angular';
import { SharedAngular } from '../../@types/sharedAngular';
angular
  .module('fgFiles')
  .factory('fgFileListService', [
    '$q',
    'lodashService',
    'guidService',
    fgFileListService
  ]);

function fgFileListService(
  $q: IQService,
  lodashService: Lodash,
  guidService: SharedAngular.GuidService
) {
  // A list of all the file controls
  // These are identified by a combination of the fieldName AND the stepId
  // This is because the fieldName is unique only within a Step.
  const _fileControls: FileControl[] = [];
  let _form = undefined;

  const service = {
    files: [],
    addFile: addFile,
    doesFileControlExist: doesFileControlExist,
    getFilesForStep: getFilesForStep,
    getFilesForControl: getFilesForControl,
    getForm: getForm,
    clearFiles: clearFiles,
    removeFile: removeFile,
    isFileListDirty: isFileListDirty,
    isFileRequired: isFileRequired,
    registerFileControl: registerFileControl,
    getAnyFileListInvalid: getAnyFileListInvalid,
    getFileListFileCount: getFileListFileCount,
    removeFileControlsForFlow: removeFileControlsForFlow,
    removeAllFileControls: removeAllFileControls,
    removeFileControlsForFlowStep: removeFileControlsForFlowStep,
    setFileListInvalid: setFileListInvalid,
    storeForm: storeForm
  };

  return service;

  //////////// Public API Methods
  function addFile(file: FileListFile, fieldName: string, stepId: Guid) {
    const fileControl = getFileControl(fieldName, stepId);
    fileControl.isDirty = true;

    if (
      !fileControl.files.some(
        (f) => f.id.toLowerCase() === file.id.toLowerCase()
      )
    ) {
      fileControl.files.push(file);
    }
  }

  function clearFiles(stepId: Guid) {
    const stepGuid = getEmptyGuidIfNullOrEmpty(stepId);
    lodashService.forEach(_fileControls, function (control) {
      lodashService.remove(control.files, function (f) {
        return f.stepId === stepGuid;
      });
    });
  }
  function doesFileControlExist(fieldName: string, stepId: Guid) {
    const found = lodashService.find(_fileControls, function (l) {
      return l.fieldName === fieldName && l.stepId === stepId;
    });
    return found !== undefined;
  }

  function getFilesForStep(stepId: Guid) {
    const stepGuid = getEmptyGuidIfNullOrEmpty(stepId);
    const controlsForStep = _fileControls.filter(
      (control) => control.stepId === stepGuid
    );
    return controlsForStep.map((control) => {
      return {
        fieldName: control.fieldName,
        files: control.files
      } as FieldFiles;
    });
  }

  function getFilesForControl(fieldName: string, stepId: Guid) {
    const control = getFileControl(fieldName, stepId);
    if (!control) {
      return [];
    }

    return control.files;
  }

  function getForm() {
    return _form;
  }

  function isFileListDirty(fieldName: string, stepId: Guid) {
    const fileList = getFileControl(fieldName, stepId);
    return fileList.isDirty;
  }

  function isFileRequired(fieldName: string, stepId: Guid) {
    const fileList = getFileControl(fieldName, stepId);
    return fileList && fileList.isRequired;
  }

  function getFileListFileCount(fieldName: string, stepId: Guid) {
    const fileList = getFileControl(fieldName, stepId);
    return fileList != null ? fileList.files.length : 0;
  }

  function registerFileControl(
    fieldName: string,
    stepId: Guid,
    flowInstanceId: Guid,
    required: boolean
  ) {
    const found = _fileControls.some((control) => {
      return (
        control.fieldName === fieldName &&
        control.flowId === flowInstanceId &&
        control.stepId === stepId
      );
    });

    if (!found) {
      _fileControls.push({
        fieldName: fieldName,
        stepId: getEmptyGuidIfNullOrEmpty(stepId),
        flowId: flowInstanceId,
        files: [],
        isDirty: false,
        isRequired: required,
        invalid: false
      });
    }
  }

  function getAnyFileListInvalid(flowId: Guid) {
    //why do required fields on their own not work
    const forFlow = lodashService.filter(_fileControls, function (c) {
      return c.flowId == flowId;
    });

    const invalid = lodashService.some(forFlow, function (f) {
      return f.invalid == true;
    });

    return invalid;
  }

  function setFileListInvalid(
    fieldName: string,
    stepId: Guid,
    flowId: Guid,
    invalid: boolean
  ) {
    const fileControl = getFileControl(fieldName, stepId);

    if (fileControl) {
      fileControl.invalid = invalid;
      fileControl.flowId = flowId;
    }
  }

  function removeFileControlsForFlow(flowId: Guid) {
    lodashService.remove(_fileControls, function (control) {
      return control.flowId == flowId;
    });
  }

  function removeAllFileControls() {
    _fileControls.length = 0;
  }

  function removeFileControlsForFlowStep(flowId: Guid, stepId: Guid) {
    lodashService.remove(_fileControls, function (control) {
      return control.flowId === flowId && control.stepId === stepId;
    });
  }

  function removeFile(file, fieldName: string, stepId: Guid) {
    const fileList = getFileControl(fieldName, stepId);
    const index = lodashService.findIndex(fileList.files, function (f) {
      return f.id === file.id;
    });
    if (index !== -1) {
      fileList.files.splice(index, 1);
      fileList.isDirty = true;
    }

    return $q.when();
  }

  function storeForm(form) {
    _form = form;
  }

  //////////// Private Methods
  function getEmptyGuidIfNullOrEmpty(stepId: Guid) {
    if (stepId == undefined || stepId == '') {
      //stepId will be undefined if we are uploading a file for a flow not yet started
      //to save having to pass in empty guid id everywhere, handle this here
      stepId = guidService.empty();
    }
    return stepId;
  }

  function getFileControl(fieldName: string, stepId: Guid) {
    return _fileControls.find(
      (control) => control.fieldName === fieldName && control.stepId === stepId
    );
  }
}

export type FgFileListServiceType = ReturnType<typeof fgFileListService>;
export type FileListFile = {
  stepId: Guid;
  filename: string;
  id: Guid;
  key: string;
  downloadLink: string;
  size: number;
};
type FileControl = {
  fieldName: string;
  stepId: Guid;
  flowId: Guid;
  files: FileListFile[];
  isDirty: boolean;
  isRequired: boolean;
  invalid: boolean;
};
export type FieldFiles = {
  fieldName: string;
  files: FileListFile[];
};

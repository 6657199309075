angular.module('fg').directive('fgDropzone', function () {
  return {
    templateUrl: 'angular-form-gen/edit/canvas/dropzone/dropzone.ng.html',
    link: function ($scope, $element, $attrs, ctrls) {
      const el = $element[0];
      el.addEventListener('dragenter', dragEnter);
      el.addEventListener('dragleave', dragFinished);
      el.addEventListener('drop', dragFinished);
      el.addEventListener('dragend', dragFinished);

      function dragEnter(event) {
        this.classList.add('over');
      }

      function dragFinished(event) {
        this.classList.remove('over');
      }

      $scope.hideDropArea = function hideDropArea() {
        const dragging = $scope.dragging;
        const draggingFieldIndex = $scope.draggingFieldIndex;
        const $index = $scope.$index;

        return (
          (!dragging && $index === undefined) ||
          (dragging &&
            draggingFieldIndex !== undefined &&
            (draggingFieldIndex === $index ||
              (draggingFieldIndex === 0 && $index === undefined) ||
              draggingFieldIndex - 1 === $index)) ||
          (dragging &&
            $scope.allowedDropAreaIndex !== undefined &&
            $scope.allowedDropAreaIndex !== $index)
        );
      };

      $scope.showDragArea = function showDragArea() {
        const dragging = $scope.dragging;
        const $index = $scope.$index;

        return (
          dragging &&
          (!$scope.allowedDropAreaIndex ||
            $index === $scope.allowedDropAreaIndex)
        );
      };
    }
  };
});

angular.module('fg').controller('fgEditController', [
  '$scope',
  'fgUtils',
  '$location',
  'fgConfig',
  'pubsubService',
  '$timeout',
  'APP_CONFIG',
  'flowinglyConstants',
  function (
    $scope,
    fgUtils,
    $location,
    fgConfig,
    pubsubService,
    $timeout,
    appConfig,
    flowinglyConstants
  ) {
    $scope.templates = angular.copy(fgConfig.fields.templates);

    if (!appConfig.enableFormFieldReCaptcha) {
      // filter out the recaptcha field from the list in form designer
      $scope.templates = $scope.templates.filter(
        (field) => field.type !== flowinglyConstants.formFieldType.RECAPTCHA
      );

      // to remove the recaptcha field from the canvas designer where the user looks their form
      var recaptchaField = $scope.schema?.fields?.find((f) => f.type === flowinglyConstants.formFieldType.RECAPTCHA);
      if (recaptchaField) {
        recaptchaField.hide = true;
      }
    }

    $scope.$watch(function (newValue, oldValue) {
      var schema = $scope.schemaCtrl.model();

      // to disable the recaptcha field from field when a recaptcha field is present in the form
      const recaptchaField = $scope.templates.find(
        (field) => field.type === flowinglyConstants.formFieldType.RECAPTCHA
      );
      if (recaptchaField) {
        recaptchaField.disabled = $scope.schema.fields.some(
          (field) => field.type === flowinglyConstants.formFieldType.RECAPTCHA
        );
      }

      // Seems that this watch is sometimes fired after the scope has been destroyed(?)

      if (schema) {
        var fields = schema.fields;

        if (fields) {
          var i = fields.length;

          while (--i >= 0 && !schema.$$_invalid) {
            schema.$$_invalid = fields[i].$$_invalid;

            fields[i].disabled =
              (fields[i].type === 'image' && fields[i].disabled) ||
              fields[i].type === 'fileupload';
          }
        }
      }
    });
  }
]);

import angular from 'angular';
import { SharedAngular } from '../../@types/sharedAngular';
import { APP_CONFIGType } from '@Shared.Angular/flowingly.services/APP_CONFIG';

angular
  .module('fg')
  .directive('fgFieldRecaptcha', [
    'fgFieldRecaptchaLinkFn',
    function (fgFieldRecaptchaLinkFn) {
      return {
        templateUrl:
          'angular-form-gen/field-templates/default/recaptcha.ng.html',
        controller: [
          '$scope',
          '$window',
          'pubsubService',
          'APP_CONFIG',
          function (
            $scope: angular.IScope,
            $window: angular.IWindowService,
            pubsubService: SharedAngular.PubSubService,
            appConfig: APP_CONFIGType
          ) {
            var ctrl = this;

            function resetRecaptcha() {
              $scope.form.data[$scope.field.schema.name] = null;
              $window.grecaptcha.reset();
            }

            pubsubService.subscribe('CLEAR_CAPTCHA', function () {
              resetRecaptcha();
            });

            ctrl.renderRecaptcha = function () {
              $window.grecaptcha?.render('recaptcha-container', {
                sitekey: appConfig.recaptchaSiteKey,
                callback: () => ctrl.loadRecaptchaResponse(),
                'expired-callback': () => ctrl.loadExpiredCallback()
              });
            };

            ctrl.loadRecaptchaResponse = function () {
              var recaptchaResponse = $window?.grecaptcha?.getResponse();
              $scope.form.data[$scope.field.schema.name] = recaptchaResponse;
            };

            ctrl.loadExpiredCallback = function () {
              $scope.form.data[$scope.field.schema.name] = null;
            };
          }
        ],
        controllerAs: 'ctrl',
        bindToController: true,
        scope: true,
        link: fgFieldRecaptchaLinkFn
      };
    }
  ])
  .factory('fgFieldRecaptchaLinkFn', [
    function () {
      return function ($scope, $element, $attrs, ctrl) {
        ctrl.renderRecaptcha();
      };
    }
  ]);
